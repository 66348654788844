

















































































































































import LsEditor from '@/components/editor.vue'
import lsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { Component, Vue } from 'vue-property-decorator'
import {
    apiDistributionDetails,
    apiDistributionSet
} from '@/api/distribution/distribution'
@Component({
    components: {
        MaterialSelect,
        lsDialog,
        LsEditor
    }
})
export default class DistributionGradeEdit extends Vue {
    /** S Data **/

    list: any = {}

    /** E Data **/

    /** S Methods **/

    onSubmit() {
        apiDistributionSet({ ...this.list })
            .then(() => {
                this.detail()
                this.$message.success('修改成功!')
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    // 详情
    detail() {
        apiDistributionDetails({})
            .then(res => {
                this.list = res
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.detail()
    }
    /** E Life Cycle **/
}
